/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(4 4 4 / 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .modal {
    background-color: #1a2235;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    max-height: 80%;
    max-width: 80%;
    overflow-y: auto;
    padding: 20px;
    position: relative;
   }

  
  .close-button {
    background: #0e111b;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: white;
    position: absolute;
    right: 10px;
    /* padding: 5px; */
    padding-top: 3px;
    border-radius: 7px;
    top: 15px;
  }
  
  .close-button:hover {
  background:#1f2639;
  }
  /* Modal.css */

.card-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(4 4 4 / 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .card-modal {
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    max-height: 80%;
    max-width: 80%;
    overflow-y: auto;
    padding: 20px;
    position: relative;
    align-items: flex-start;
    background-color: #0e111b;
    border: 2px solid #21273a;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 400px;
    padding-left: 30px;
    padding-right: 30px;
    width: 400px;
   }
.account-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2px 0 16px;
}
.account-card-header{
    margin: -20px 0 16px;
}