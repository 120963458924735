
.App {
  text-align: center;
}

header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.logo{
  width: 40px;
  height: 40px;
  padding-right: 20px;
}

.eth{
  width: 20px;
  height: 20px;
  padding-right: 10px;

}

.leftH {
  display: flex;
  align-items: center;
  gap: 20px;
}

.rightH {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.headerItem:hover {
  background-color: rgb(34, 42, 58);
  cursor: pointer;
}

.connectButton {
  background-color: #243056; 
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #ffffff;
  margin: 7px;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #3b4874
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  width: 400px;
  background-color: #0E111B;
  border: 2px solid #21273a;
  min-height: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 30px;
  padding-right: 30px;
}


.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #3a4157;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 86px;
  left: 180px;
  color: #5F6783;
  border: 3px solid #0E111B;
  font-size: 12px;
  transition: 0.3s
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5F6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
  rotate: 180deg;
}

.inputs {
  position: relative;
}

.assetOne {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 36px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 135px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetOne:hover{
  cursor: pointer;
}

.assetTwo:hover{
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #243056; 
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: #5981F3;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 8px;
}

.swapButton[disabled] {
  background-color: #243056;
  opacity: 0.4;
  color: #5982f39b;
}

.swapButton[disabled]:hover {
  cursor: not-allowed;
  background-color: #243056;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #3b4874
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  /* padding-top: 20px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}


/* Media Query for Small Devices */
@media (max-width: 767px) {
  .mainWindow {
      margin: 20px;
  }
}

/* Media Query for Medium Devices */
@media (min-width: 768px) and (max-width: 1023px) {
  .mainWindow {
      margin: 15px;
  }
}

/* Media Query for Large Devices */
@media (min-width: 1024px) {
  .mainWindow {
      margin: 20px;
  }
}

/* Mobile menu styles */

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  /*width: 70%;*/
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.menuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.menuContent {
  align-items: flex-start;
  background-color: #0c101a;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
  padding-right: 50rem;
  
}

.mobileMenuItem {
  color: #fff;
    /* margin-bottom: 1rem; */
    opacity: .8;
    text-align: left;
    text-decoration: none;
    width: 190%;
    border-bottom: 1px solid;
    padding: 15px 0;
}

/* Burger menu styles */

.burgerMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.burgerLine {
  width: 100%;
  height: 3px;
  background-color: #fff;
  margin: 1px;
  transition: transform 0.3s ease-in-out;
  border-radius: 15px;
}

.burgerLine:first-child {
  transform: translateY(-8px) translateX(-5px) rotate(0deg);
}

.burgerLine:last-child {
  transform: translateY(8px) translateX(-5px) rotate(0deg);
}

/* Add the "open" class to the burger menu when the menu is open */
.burgerMenu.open .burgerLine:first-child {
  transform: translateY(0) rotate(180deg);
}

.burgerMenu.open .burgerLine:last-child {
  transform: translateY(0) rotate(-180deg);
}

.burgerMenu.open .menuContent {
  transform: translateX(50) rotate(0deg);
}

.burgerMenu.open .mobileMenu {
  transform: translateX(50) rotate(0deg);
}

/* CSS for the menu */
.header {
  /* ... existing styles ... */
  position: relative; /* Added position property */
}

.burgerMenu {
  /* ... existing styles ... */
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 35px;
    z-index: 10;
}

.burgerMenu.open {
  /* Styles for open menu */
  transform: translateX(0%); /* Slide menu in from right */
}

.mobileMenu {
  /* ... existing styles ... */
  position: fixed; /* Added position property */
  top: 0; /* Position at top of viewport */
  right: 0; /* Position at right of viewport */
  height: 100%; /* Set height to 100% to cover entire viewport */
  /* width: 100%; */ /* Set width to 70% for the sliding effect */
  background-color: #192134; /* Add background color to the menu */
  padding: 2rem; /* Add padding for menu items */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transform: translateX(100%); /* Initial position outside viewport */
  transition: transform 0.3s ease-in-out; /* Add transition for smooth sliding effect */
  z-index: 9;
}

.mobileMenu.open {
  /* Styles for open menu */
  transform: translateX(70%); /* Slide menu in from right */
}
@media (max-width: 767px) {
  .mobileMenu.open {
    /* Styles for open menu */
    transform: translateX(20%); /* Slide menu in from right */
  }
}

.rightH {
  justify-content: flex-end;
  padding-right: 15px;
}


/* Media Query for Small Devices menu itms */
@media (max-width: 767px) {
  .menuContent {
    padding-right: 0rem;
    
  }
}

/* Media Query for Medium Devices */
@media (min-width: 768px) and (max-width: 1023px) {
  .menuContent {
    padding-right: 40rem;
    
  }
}

/* Media Query for Large Devices */
@media (min-width: 1024px) {
  .menuContent {
    padding-right: 50rem;
    
  }
}


/* Media Query for Small Devices menu itms */
@media (max-width: 767px) {
  .headerItem {
    display: none;
  }
}


.expandableContainer {
    background-color: #243056;
    border-radius: 12px;
    color: rgb(255, 255, 255);
    font-weight: 700;
    display: flex;
    height: auto;
    min-height: 55px;
    justify-content: center;
    margin-bottom: 30px;
    margin-top: 8px;
    transition: .3s;
    width: 100%;
    padding: 5px;
    flex-direction: column;
}

.Gas {
  background-color: #171d33;
  border-radius: 12px;
  padding: 15px;
  margin: 10px;
}

.GasHeader {
  text-align: left;
  margin-bottom: 10px;
}

.GasRow {
  display: flex;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 5px;
}

.GasLabel {
  text-align: left;
  font-weight: 700;
}

.GasValue {
  text-align: right;
  font-weight: 400;
}

/* Additional styles can be added as needed */
.gas-container {
  display: flex;
  justify-content: space-between; /* This will push the elements to the left and right */
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
}


.my-gas-icon {
  margin-left: auto; /* This will push it to the right */
  /* Additional styles for the right content if needed */
}


.price-container {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

.price-content {
  flex: 1;
  margin-right: 5px;/* This will make it take up the available space on the left */
}

.gas-icon-container {
  margin-left: auto;
}

.info {
  color: #51586f;
  transition: 0.3s;
}

.info:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.balance1 {
  position: absolute;
  min-width: 50px;
  height: 30px;
  top: 3px;
  right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #51586f;
  font-weight: 500;
  font-size: 12px;
  padding-right: 8px;
}

.balance2 {
  position: absolute;
  min-width: 50px;
  height: 30px;
  top: 103px;
  right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #51586f;
  font-weight: 500;
  font-size: 12px;
  padding-right: 8px;
}

.walletbalance{
  background: #192134;
    border-radius: 7px;
    color: #fff;
    /* margin-bottom: 1.5rem; */
    opacity: .8;
    padding: 5px 8px;
    width: 100%;
}
.connectButtonmenu{
background-color: #243056;
border-radius: 7px;
color: #ffffff;
font-weight: 700;
margin: 0px 0px 1.5rem 0px;
padding: 10px 20px;
transition: .3s;
}