.tokenshow {
    /*display: flex;*/
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Optional: Set a specific height for the centered content */
  }

  /* styles.css */
.containertoken {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.cardtoken {
  border: 1px solid #000;
  border-radius: 8px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
  padding: 10px;
  margin: 10px;
  width: 300px;
  display: flex;
  align-items: center;
}

.logotoken {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
}

.infotoken {
  flex: 1;
  font-size: 14px;
}

.rowtoken {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 58px;
  padding-right: 10px;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.searchInputtoken {
  background: transparent;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: azure;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    width: 75%;
}

@media (max-width: 767px){
.rowtoken {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
}