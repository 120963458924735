/* Apply styles to buttons */
.connect-button {
    display: flex;
    background-color: #0e111b;
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    width: 300px;
    margin: 10px;
    padding: 20px 20px;
    text-transform: capitalize;
    transition: background-color .3s;
    flex-direction: row;
    align-content: space-around;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
  }
  
  /* Apply hover effect */
  .connect-button:hover {
    background-color: #1f2639;
  }
  
  .connector-content {
    display: flex;
    align-items: center;
  }
  
  .connector-img {
    width: 30px; /* Adjust the width as needed */
    height: 30px; /* Adjust the height as needed */
    margin-right: 8px; /* Adjust the margin as needed */
  }
  
  .connector-name {
    /* Additional styles for the connector name */
  }
  /* YourComponent.css */

.container {
    align-items: center;
    background-color: #0e111b;
    border-radius: 25px 25px 25px 25px;
    display: flex;
    height: 30px;
    padding: 6px;
  }
  .container:hover{
  border:1px;
  border-color: #5f6783;
  }
  
  .avatar {
    margin-right: 7px;
    margin-top: 3px;
    max-width: 28px; /* Set the maximum width for the avatar */
  }
  
  .avatar-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
  
  .address {
    text-transform: capitalize;
    display: flex;
    color: #5f6783;
    font-size: 16px;
    background: #1f2639;
    /*margin-right: 5px;*/
    border-radius: 7px 25px 25px 7px;
    padding:4px;
    margin-top: -0.3px;
  }
  
  .name {
    color: #5f6783;
    margin-right: 5px;
    font-size: 16px;
    display: none;
  }
  
  .address-text {
    color: #fff;
    font-size: 15px;
    /* margin-right: 5px; */
    /*padding: 7px;*/
    background: #1f2639;
    border-radius: 5px;
    padding-left: 5px;
  }

  .walletcardbuttom:hover{
    color: #d6d1d1;
  }
  
  .disconnect {
    margin-top: -1.5px;

  }
  
  .disconnect-button {
    background-color: #1f2639;
    border: none;
    border-radius: 5px;
    color: #fff;
    /*margin-bottom: 1.2px;*/
    cursor: pointer;
    /*padding: 7px 10px;*/
  }

   .combined-class {
    
    /* styles from disconnect-button class */
    background-color: #1f2639;
    color: #fff;
    display: flex;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    border-radius: 7px;
    padding: 5px 10px;
  }
  
  .combined-class:hover {
    background-color: #192134; /* Example background color on hover */
  }

  .walletcardbuttom{
    background: #1f2639;
    /* border-radius: 5px; */
    color: #fff;
    font-size: 15px;
    padding-left: 5px;
    border: 0;
  }
  
  